p, a{
    font-family: $font-title;
    font-size: 1.1vw;
    line-height : 1.3;
    color : $noir;  
    font-variation-settings:
        "wdth" 120,
        "wght" $light;

    &.subtitle{
        font-size : 1.3vw;
        line-height : 1.2;
        text-transform : uppercase;
        color : $noir;
        font-variation-settings:
            "wdth" 120,
            "wght" $regular;
    }

    @media screen and (max-width:990px){
        font-size: 4vw;

        &.subtitle{
            font-size : 4vw;
        }
    }
}


h1{
    font-family: $font-title;
    font-size: 5vw;
    line-height : .90;
    color : $noir;
    text-transform: uppercase;
    font-variation-settings:
        "wdth" 120,
        "wght" $heavy;

    @media screen and (max-width : 990px){
        font-size: 10vw;
        line-height : .9;
    }
}

h2{
    font-family: $font-title;
    font-size: 3vw;
    line-height : .90;
    color : $noir;
    text-transform : uppercase;
    font-variation-settings:
        "wdth" 120,
        "wght" $heavy;

    @media screen and (max-width:990px){
        font-size: 8vw;
    }
}


h3{
    font-family: $font-title;
    font-size: 1.1vw;
    line-height : 1.2;
    color : $noir;
    text-transform : uppercase;  
    text-align : center;
    white-space : nowrap;
    font-variation-settings:
        "wdth" 120,
        "wght" $black;

    @media screen and (max-width:990px){
        font-size: 5vw;
    }
}

h4{
    font-family: $font-title;
    font-size: 1.1vw;
    font-weight : 600;
    line-height : 1.2;
    color : $blanc;
    font-variation-settings:
        "wdth" 120,
        "wght" $black;

    @media screen and (max-width:990px){
        font-size: 5vw;
    }
}