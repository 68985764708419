@import "../style.scss";

.nav{
    position : fixed;
    top : 0;
    left : 0;
    display : flex;
    justify-content: space-between;
    width : 100%;
    padding : 2.5vh 5vw 2.5vh 8.5vw;
    background-color: $noir;
    transform : translateY(-100%);
    animation : slideTop 1s ease .2s forwards;
    z-index : 200;

    @media screen and (max-width:990px){
        position : fixed;
        align-items: center;
        padding : 2vh 30px;
    }

    &_logo{
        width : 3vw;
        height : 3vw;
        @media screen and (max-width:990px){
            width : 9vw;
            height : 9vw;
        }

        img{
            width : 100%;
            height : 100%;
            object-fit: contain;
            object-position: center;
        }
    }

    &_links{
        position : relative;
        display : flex;
        align-items : center;
        justify-content: space-between;
        gap : 3vw;

        @media screen and (max-width:990px){
            display : none;
        }

        &-link{
            font-size : 1.1vw;
            color : $blanc;
            font-family : $font-title;
            font-variation-settings:
                "wdth" 120,
                "wght" $regular;
            text-transform : uppercase;
            cursor: pointer;
        }

        .bouton{
            min-height : 5vh;
            border-radius : 100vmax;
        }
    }

    &_menu{
        position : relative;
        display : none;
        width : 8vw;
        height : 6vw;

        &-barre{
            position : absolute;
            left : 0;
            width : 100%;
            height : 2px;
            background-color: $secondary;
            will-change : transform;
            transition : .5s ease;

            &:nth-child(1){
                top : 0;
                transform-origin : top;
            }

            &:nth-child(2){
                top : 50%;
                transform : translateY(-50%);
            }

            &:nth-child(3){
                bottom : 0;
                transform-origin : bottom;
            }
        }

        &.active{
            .nav_menu-barre:nth-child(1){
                top : 50%;
                transform : translateY(-50%) rotate(45deg) ;
            }

            .nav_menu-barre:nth-child(2){
                opacity : 0;
            }

            .nav_menu-barre:nth-child(3){
                top : 50%;
                transform : translateY(-50%) rotate(-45deg) ;
            }
        }

        @media screen and (max-width:990px){
            display : flex;      
        }
    }
}