// Marron
// $primary : #796254;
// $secondary : #ecdbd0;

// Vert
$primary : #91a8a3;
$secondary : #E7ECEB;

$noir : #2A2E35;
$blanc : #F5F5F5;
$beige : #D4CFC9;


// $gris : #9f9da8;


// $vert1 : #1f6b57;
// $vert2 : #798a7f;


// $marron1 : #A9907E;
// $marron2 : #bea290;
// $marron3 : #e7c8b4;
// $marron4 : #f5d9c6;
// $marron5 : #F6E9DE;

