@import "../style.scss";


.lightbox{
    position : fixed;
    top : 0;
    left : 0;
    display : flex;
    justify-content : center;
    align-items: center;
    width : 100%;
    height : 100vh;
    background-color: $blanc;  
    padding : 10vh 15vw 10vh 15vw;
    transition : opacity .3s ease;
    z-index : 201;

    @media screen and (max-width : 990px){
        flex-direction: column;
        gap : 5vh;
        padding : 10vh 30px 5vh 30px;
        height: var(--heightJs);
    }

    &.fadeOut{
        opacity : 0;
    }

    &_image{
        display : flex;
        justify-content: center;
        align-items: center;
        width : 45vw; 
        height : 80vh;
        overflow: hidden;

        @media screen and (max-width : 990px){
            width : 100%;
            height : 100%;
        }

        img{
            width : auto;
            height : 100%;
            object-fit: contain;
            @media screen and (max-width : 990px){
                width : 100%;
                height : 100%;
            }
        }
    }

    &_arrows{
        position : absolute;
        display : flex;
        align-items: center;
        justify-content : space-between;
        width : 45vw; 
        height : 80vh;

        @media screen and (max-width : 990px){
            display : none;
            justify-content: flex-end;
            gap : 5vw;
            bottom : 5vh;
        }

        .arrow{
            display : flex;
            justify-content: center;
            align-items: center;
            width : 3vw;
            height : 3vw;
            background-color: $noir;
            border-radius : 100vmax;
            transition : .3s ease;
            cursor : pointer;

            @media screen and (max-width : 990px){
                width : 15vw;
                height : 15vw;
            }

            &.lightbox-prev{
                margin-left : -1.5vw;
                svg{
                    transform : rotate(90deg);
                }
            }

            &.lightbox-next{
                margin-right : -1.5vw;
                svg{
                    transform : rotate(-90deg);
                    
                } 
            }

            svg{
                width : 1.2vw;
                will-change: transform;
                fill : $beige;
                transition : .3s ease;
                @media screen and (max-width : 990px){
                    width : 5vw;
                    height : 5vw;
                }
            }

            &:hover{
                background-color: $beige;
                svg{
                    fill : $noir;
                }
            }
        }
    }

    &_close{
        position : absolute;
        top : 2vw;
        right : 2vw;
        width : 3vw;
        height : 3vw;
        display : flex;
        justify-content: center;
        align-items: center;
        border-radius : 100%;
        background-color: $noir;
        transition : .5s ease;
        will-change : transform;
        cursor : pointer;

        @media screen and (max-width : 990px){
            width : 15vw;
            height : 15vw;
        }

        &-barre{
            position : absolute;           
            width : 1.5vw;
            height : 1px;
            background-color: $beige;
            transition : .3s ease;
            @media screen and (max-width : 990px){
                width : 5vw;
            }

            &--1 {
                top : 50%;
                left : 50%;
                transform : translate(-50%, -50%) rotate(45deg);
            }

            &--2 {
                top : 50%;
                left : 50%;
                transform : translate(-50%, -50%) rotate(-45deg);
            }
        }

        &:hover{
            background-color: $beige;
            .lightbox_close-barre{
                background-color : $noir;
            }
        }
    }
}