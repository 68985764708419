@import "../style.scss";

.galerie{
    display : flex;
    flex-direction: column;
    align-items: center;
    gap : 10vh;
    background-color: $blanc;
    padding-bottom : 20vh;

    &_container {       
        display : flex;
        justify-content: space-between;
        gap : 3.5vw;
        padding : 0 8.5vw 10vh 8.5vw;
        @media screen and (max-width : 990px){
            gap : 10px;
        }

        .box{
            display : flex;
            flex-direction: column;
            gap : 3.5vw;
            width : calc( 100% / 4);
            @media screen and (max-width : 990px){
                gap : 10px;
                width : calc( 100% / 3);
            }
        }
        
        &-image{
            width : 100%;
            height : auto;
            overflow:  hidden;

            img{
                width : 100%;
                height : 100%;
                object-fit: cover;
                transition : .7s ease;
                cursor : pointer;
            }

            &:hover{
                img{
                    transform : scale(1.1)
                }
            }

            &.active{
                display : flex;
            }

            &.inactive{
                display : none;
            }
        }
    }
}

