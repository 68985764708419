@import "../style.scss";

.entreprise{
    display : flex;
    background-color: $blanc;
    @media screen and (max-width:990px){
        flex-direction: column-reverse;
    }

    &_img{
        width : 45vw;
       // padding : 8.5vw 4.25vw 8.5vw 8.5vw;
       border-right : 2px solid $noir;
        @media screen and (max-width:990px){
            width : 100%;
            height : 40vh;
            padding : 0;
            border-right : none;
            border-top : 2px solid $noir;
        }

        &-img{
            width : 100%;
            height : 100%;
            overflow : hidden;

            img{
                width : 100%;
                height : 100%;
                object-fit: cover;
            }
        }
    }

    &_bloc{
        display : flex;
        align-items: flex-end;
        width : 55vw;
        //padding : 8.5vw 8.5vw 8.5vw 4.25vw;
        padding : 8.5vw;

        @media screen and (max-width:990px){
            width : 100%;
            padding : 15vh 30px;
        }

        &-container{
            display : flex;
            flex-direction: column;
            gap : 10vh;

            @media screen and (max-width:990px){
                width : 100%;
            }       
            
            &--title{
                display : flex;
                flex-direction: column;
                gap : 3vh;

                h2{
                    color : $noir;
                }

                .subtitle{
                    color : $noir;
                }
            }

            &--txt{
                display : flex;
                flex-direction: column;
                gap : 5vh;

                @media screen and (max-width:990px){
                    width : 100%;
                }
            }

            // .bouton{
            //     &:hover{
            //         color : $secondary;
            //         &::after{
            //             background-color: $noir;
            //         }
            //     } 
            // }
        }
    }

    &.entreprise-contact{

        .entreprise_bloc{
            width : 45vw;
            @media screen and (max-width:990px){
                width : 100%;
            }
        }  

        .entreprise_img{
            width : 55vw;
            @media screen and (max-width:990px){
                width : 100%;
            }
        }
    }
}