.bouton{
    position : relative;
    display : flex;
    justify-content: center;
    align-items: center;
    flex-wrap : nowrap;
    width : fit-content;
    min-height : 7vh;
    padding : 0 2vw;
    text-transform: uppercase;
    font-family : $font-title;
    font-variation-settings:
        "wdth" 120,
        "wght" $regular;
    cursor : pointer;
    overflow: hidden;
    transition : .3s ease;
    z-index : 1;

    @media screen and (max-width:990px){
        padding : 0 10vw;    
        min-height : 7vh;
        white-space : nowrap;
    }  

    &:hover{
        &::after{
            height : 100%;
        }      
    }

    &_beige{
        background-color: $beige;
        color : $noir;
        border : 2px solid $noir;
        &:hover{
            color : $beige;
            &::after{
                background-color: $noir;
            }
        }  
    }

    &_noir{
        background-color: $noir;
        color : $secondary;
        border : 2px solid transparent;
        &:hover{
            color : $noir;
            border-color : $noir;
            &::after{
                background-color: $blanc;
            }
        }  
    }

    &_noirTrait{
        background-color: transparent;
        color : $noir;
        border : 2px solid $noir;
        &:hover{
            color : $blanc;
            &::after{
                background-color: $noir;
            }
        }  
    }


    &::after{
        position : absolute;
        content : "";
        bottom : 0;
        left : 0;
        width : 100%;
        height : 0;
        transition : .3s ease;
        z-index : -1;
    }
}