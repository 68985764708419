@import "../style.scss";

.accelerateurs{
    width : 100%;
    padding : 20vh 8.5vw 0 8.5vw;
    @media screen and (max-width:990px){
        padding : 10vh 30px 0 30px;
    }

    &_container{
        display : flex;
        justify-content: space-between;
        flex-wrap : wrap;
        gap : 1vw;
        @media screen and (max-width:990px){
            gap : 2vw;
        }
  
        .bouton{
            min-height : 5vh;

            @media screen and (max-width:990px){
                min-height : 4vh;
                font-size : 3vw;
                padding : 0 5vw;
                flex-grow: 1;
                border-width : 1px;

                &:last-of-type{
                    flex-grow : 0;
                }
            }

            &:hover, &.active{
                color : $blanc;
                &::after{
                    background-color: $noir;
                    height : 100%;
                }
            }  
        }
    }
}