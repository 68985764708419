@import "../style.scss";

.header{
    position : relative;
    display : flex;
    width : 100%;
    height : 110vh;
    background-color: $blanc;
    
    @media screen and (max-width : 990px){
        flex-direction : column;
        //height: var(--heightJs);
    }

    &_txt{
        display : flex;
        flex-direction: column;
        justify-content: flex-end;
        gap : 15vh;
        width : 55vw;
        height : 100%;
        padding : 10vh 8.5vw 20vh 8.5vw;
        
        @media screen and (max-width : 990px){
            gap : 5vh;
            width : 100%;
            height : 55vh;
            padding : 7.5vh 30px;
        }

        &-title{
            display : flex;
            flex-direction: column;
            gap : 3vh;

            h1{
                opacity : 0;
                transform : translateY(-50%);
                animation : slideTop 1s ease .4s forwards;

                span{
                    color : transparent;
                    -webkit-text-stroke: 2px $noir;
                    @media screen and (max-width : 990px){
                        -webkit-text-stroke: 1px $noir;
                    }
                }
            }

            .subtitle{
                opacity : 0;
                color : $noir;
                transform : translateY(-50%);
                animation : slideTop 1s ease .6s forwards;
                
                @media screen and (max-width : 990px){
                    //font-size : 3vw;
                }
            }
        }  

        &-button{
            opacity : 0;
            transform : translateY(-50%);
            animation : slideTop 1s ease .8s forwards;
        }
    }

    &_img{
        position : relative;
        width : 45vw;
        height : 100%;
        overflow : hidden;
        border-left : 2px solid $noir;

        @media screen and (max-width : 990px){
            width : 100%;
            height : 55vh;
            border-left : none;
            border-top : 2px solid $noir;
        }

        img{
            width : 100%;
            height : 110vh;
            object-fit: cover;
        }

        &::after{
            position : absolute;
            content : "";
            top : 0;
            left : 0;
            width : 100%;
            height : 100%;
            background-color: $blanc;
            animation : curtain 1s linear .5s forwards;
        }
    }
}