@import "../style.scss";

.menu{
    position : fixed;
    top : 0;
    left : 0;
    display : none;
    justify-content: center;
    align-items: center;
    width : 100%;
    height : 100vh;
    padding : 15vh 30px 10vh 30px;
    transform : translateY(-100%);
    transition : .5s ease;
    z-index : 150;
    background-color: $blanc;
    overflow : hidden;

    &_container{
        display : flex;
        flex-direction: column;
        align-items: center;
        gap : 7.5vh;

        &-link{
            font-family: $font-title;
            font-size : 6vw;
            line-height : 1.2;
            text-transform : uppercase;
            color : $noir;
            font-variation-settings:
                "wdth" 120,
                "wght" $medium;
        }

        .bouton{
            font-size : 6vw;
            min-height : 7vh;
            font-variation-settings:
                "wdth" 120,
                "wght" $medium;
        }
    }

    &.active{
        transform : translateY(0);
    }

    @media screen and (max-width : 990px){
        display : flex;
    }
}