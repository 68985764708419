@import "../style.scss";


.realisations{
    position : relative;
    width : 100%;
    background-color: $blanc;

    &_container{
        display : flex;
        height : 100vh;
        
        @media screen and (max-width : 990px){
            //height : 90vh;
            flex-direction: column;
            height : auto;
        }
    
        &-txt{
            position : relative;
            display : flex;
            align-items: center;
            width : 45vw;
            padding : 7.5vw 8.5vw;
            
            @media screen and (max-width : 990px){
                width : 100%;
                //height : 55vh;
                padding : 10vh 30px;
            }
    
            .container-txt{
                display : flex;
                flex-direction: column;
                gap : 10vh; 
                width : 28vw;
                @media screen and (max-width : 990px){
                    justify-content: flex-end;
                    gap : 3.5vh;
                    width : 100%;
                }

                &_content{
                    display : flex;
                    flex-direction: column;
                    gap : 3vh;

                    @media screen and (max-width:990px){
                        gap : 2vh;
                    }

                    &--title{
                        position : relative;
                        width : 100%;
                        overflow : hidden;

                        .slides-titles{
                            position: absolute;
                            left: 0;
                            display: flex;
                            flex-direction: column;
                        }

                        h2{         
                            font-size: 2.7vw;                
                            padding-top : 10px;       
                            @media screen and (max-width:990px){
                                font-size: 8vw;
                                padding-top : 5px;
                            }                
                        }
                    }

                    &--subtitle{
                        position : relative;
                        width : 100%;
                        overflow : hidden;

                        .slides-subtitles{
                            position: absolute;
                            left: 0;
                            display: flex;
                            flex-direction: column;
                        }
                    }  

                    &--description{
                        position : relative;
                        width : 100%;
                        overflow : hidden;

                        .slides-descriptions{
                            position: absolute;
                            left: 0;
                            display: flex;
                            flex-direction: column;
                        }

                        .description{
                            padding-bottom : 10px;
                            @media screen and (max-width:990px){
                                padding-bottom : 1px;
                            }
                        }
                    } 
                }      
            } 
            
            &--arrows{
                position : absolute;
                right : -1.5vw;
                bottom : 50%;
                transform : translateY(50%);
                z-index : 5;
    
                @media screen and (max-width : 990px){
                    left : unset;
                    right : 30px;
                    bottom : -7.5vw;
                    transform : none;
                }
    
                .arrow{
                    display : flex;
                    justify-content: center;
                    align-items: center;
                    width : 3vw;
                    height : 3vw;
                    background-color: $noir;
                    border : 2px solid $noir;
                    border-radius : 100vmax;
                    transition : .3s ease;
                    cursor : pointer;
                    @media screen and (max-width : 990px){
                        width : 15vw;
                        height : 15vw;
                    }
    
                    &.arrow-right{
                        svg{
                            transform : rotate(-90deg);
                        } 
                    }
    
                    svg{
                        width : 1.2vw;
                        will-change: transform;
                        transition : .3s ease;
                        path{
                            fill : $blanc;
                        }
                        @media screen and (max-width : 990px){
                            width : 5vw;
                            height : 5vw;
                        }
                    }
    
                    &:hover{
                        background-color: $beige;
                        @media screen and (max-width : 990px){
                            background-color: $noir;
                        }
                        svg{
                            path{
                                fill : $noir;
                                @media screen and (max-width : 990px){
                                    fill : $secondary;
                                }
                            }
                        }
                    }
                }
            }
        }
    
        &-slider{
            position : relative;
            display : flex;
            justify-content: center;
            align-items: center;
            width : 55vw;
            height : 100%;
            overflow : hidden;
            border-left : 2px solid $noir;
    
            @media screen and (max-width : 990px){
                width : 100vw;
                height : 50vh;
                border-left : none;
                border-top : 2px solid $noir;
                border-bottom : 2px solid $noir;
            }

            .coucou{
                position : relative;
                width : 35vw;
                height : 35vw;
                overflow : hidden;
                @media screen and (max-width : 990px){
                    width : 100vw;
                    height : 50vh;
                }
            }

            .slides {
                position: absolute;
                top: 0;
                left: -35vw;
                display: flex;
                height : 100%;
                @media screen and (max-width : 990px){
                    left : -100vw;
                }

                .slide{
                    width: 35vw;
                    height : 100%;
                    @media screen and (max-width : 990px){
                        width : 100vw;
                    }
        
                    img{
                        width : 100%;
                        height : 100%;            
                        object-fit: cover;    
                    }
                }
            }
        }

        &-bouton{
            padding : 10vh 30px;
            background-color: $blanc;
        }
    }
}
